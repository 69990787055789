import React from 'react';
import { lazyLoadable } from 'utils';
import TableComponentSkeleton from '../Skeletons/TableComponentSkeleton';

const TableComonentLazy = lazyLoadable(
    () => import('./TableComponent'),
    <>
        <TableComponentSkeleton />
    </>,
);

export default TableComonentLazy;