import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const BaseDateRangeSelectorStyled = styled(RangePicker)`
  height: 100%;
  border: 0;
  background: white;
  padding: 0 !important;
  justify-content: center;
  /* &.ant-picker-large { */
    /* padding: 0 0.7rem; */
    .ant-picker-input > input {
      text-align: center;
      color: #181818;
    }
  /* } */
  .ant-picker-range-separator {
    padding: 0;
    width: 1px;
    background-color: #EAECF2;
    height: 10px;
    margin: 0 0.5rem;
  }
  .ant-picker-active-bar {
    display: none;
  }
`;
