import styled from 'styled-components';

export const UserAddFormStyled = styled.form`
    height: 100%;
    .scrollable {
        height: 100%;
    }
    .invite-user {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-wrap: nowrap;
    }
    .invite-user__footer {
        margin-top: auto;
        margin-bottom: 30px;
    }
    .invite-user__bulk-upload {
        margin: 0px auto 20px;
        justify-content: center;
    }
    .invite-user__bulk-upload p {
        font-size: 18px;
        text-decoration: underline;
        margin-left: 20px;
    }
`;