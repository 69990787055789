import { Row } from 'antd';
import styled from 'styled-components';

type ProductItemStyledProps = any;
export const ProductItemStyled = styled(Row)<ProductItemStyledProps>`
  border-bottom: 1px solid rgba(128, 142, 155, 0.12);
  .item {
    height: 6.125rem;
    width: 100%;
    padding: 1rem 0 1rem 0;
    border-radius: 1rem;
    border: 1px solid transparent;
    button {
      outline: none;
      border: none;
    }
    .container__title {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .container__tools {
      .tools__row {
        flex-wrap: nowrap;
        button {
          color: #4b4dc8;
          font-size: 1.2rem;
          height: 1.25rem;
          span {
            margin: 0 auto;
          }
          svg,
          path {
            fill: #4b4dc8;
            transition: all 0.2s linear;
          }
        }
        .tools__delete {
          font-size: 1rem;
          width: 50px;
          position: relative;
          &:after {
            position: absolute;
            content: '';
            background-color: #808E9B !important;
            width: 1px;
            height: 10px;
            right: 0;
            bottom: inherit;
            top: inherit;
            left: inherit;
            opacity: 1;
          }
        }
        .tools__setting {
          width: 50px;
        }
        .divider {
          height: 2.1rem;
          border-left-color: #5e6f88;
        }
      }
    }
  }
  .mobile_stats,
  .device_icons_wrapper,
  .mobile_container__title,
  .vertical_line {
    display: none;
  }

  .container__click_rate,
  .container__impressions,
  .container__clicks {
    border-right: 2px solid #d9dde1;
  }

  .container__click_rate,
  .container__impressions,
  .container__clicks,
  .container__tools {
    width: 16%;
    text-align: center;
    p {
      font-size: 1rem !important;
    }
  }
  .container__tools {
    width: 10%;
  }
  .container__title {
    width: 30%;
    border-right: 2px solid #d9dde1;
    p {
      font-size: 1rem !important;
    }
  }
  .device_icon {
    width: 10%;
  }

  .mobile-container {
    display: none;
    width: 100%;
    padding: 0 25px;
    border-radius: 10px;
    .mobile-container__title {
      border-bottom: 1px solid rgba(128, 142, 155, 0.12);
      display: flex;
      align-items: center;
      padding: 20px 0;
      p {
        margin-top: 2px;
        font-size: 14px;
      }
    }
    .mobile-container__dropdown {
      display: none;
      &.show {
        display: block;
      }
      .dropdown__stats {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid rgba(128,142,155,0.12);
        p {
          font-size: 1rem;
        }
      }
    }
    .dropdown__tools {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px 0;
      button {
        width: 50px;
      }
      .tools__delete {
        &:after {
            position: absolute;
            content: '';
            background-color: #808E9B;
            width: 1px;
            height: 12px;
            right: 0;
          }
        height: 15px;
        margin-top: 9px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    border-bottom: none;
    margin: 0.5rem 0rem;
    .container__title,
    .container__tools {
      display: none;
    }
    .mobile_container__title {
      display: block;
      margin-left: 1rem;
    }
    .device_wrapper,
    .mobile_stats,
    .device_icons_wrapper {
      display: flex;
      align-items: center;
    }
    .mobile_stats {
      img {
        margin-left: 1rem;
      }
    }
    .container__click_rate,
    .container__impressions,
    .container__clicks,
    .device_icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .container__click_rate,
    .container__impressions,
    .container__clicks {
      padding: 0 2rem;
    }
    .device_icons_wrapper {
      .vertical_line {
        display: flex;
        height: 1.5rem;
        width: 0.1rem;
        background-color: lightgray;
        margin: 0rem 0.8rem 0rem 0rem;
      }
      svg {
        path {
          fill: lightgray;
        }
      }
    }
    .item {
      flex-direction: column;
      height: auto;
      display: none;
      .ant-col {
        min-width: 100%;
        margin: 0.5rem 0rem;
      }
    }
    .mobile-container {
      display: block;
    }
  }

  @media only screen and (max-width: 450px) {
    .container__click_rate,
    .container__impressions,
    .container__clicks {
      padding: 0 1.5rem;
    }
  }
  @media only screen and (max-width: 1200px) {
    .tools__row {
      margin-left: 20px;
      flex-direction: column;
      row-gap: 10px !important;
      margin-bottom: -10px;
      .tools__delete {
        padding-bottom: 20px;
        &:after {
            position: absolute;
            content: '';
            background-color: #808E9B;
            height: 1px !important;
            width: 12px !important;
            bottom: 0 !important;
            right: inherit !important;
          }
        width: 20px !important;
      }
      .tools__setting {
        width: auto !important;
      }
    }
    .container__click_rate,
    .container__impressions,
    .container__clicks {
      width: 17%;
      text-align: center;
      p {
        font-size: 1rem !important;
      }
    }
    .container__tools {
      width: 7%;
    }
  }
`;
