import { Button, Col, message, Row } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';
import { BulkUserAddStyled } from './BulkUserAdd.styled';
import { BaseSecondaryButton, BaseSelect } from 'components/baseComponents';
import { Text } from 'components/styles';
import { ReactComponent as DowloadIcon } from 'assets/image/svg/dowload-icon.svg';
import { ReactComponent as DocumentBulkUpload } from 'assets/image/svg/document-bulk-upload.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormPageTypeEnum } from 'utils/enums';
import Papa from 'papaparse';
import { useGetCompanies } from 'services';
import { useDropzone } from 'react-dropzone';
import { isValidFile } from 'utils/helpers/functions';

const schema = Yup.object().shape({
  company_id: Yup.string().required(),
  file: Yup.mixed(),
});

type BulkUploadFomType = {
  company_id: string;
  file: string;
};
const sampleFile =
  'data:file/csv;base64,Rmlyc3QgTmFtZSxMYXN0IE5hbWUsRW1haWwsUm9sZQpKb2huLFNub3csam9obi5zbm93QG1haWwu\n' +
  'Y29tLENvbXBhbnkgQWRtaW4KRWxtbyxTZXNhbWUsZWxtb0BtYWlsLmNvbSxXZWJzaXRlIEFkbWlu\n' +
  'CkthcmVuLE1lbWUsa2FyQGNvbSxDb21wYW55IEFkbWlu';

const BulkUserAdd = ({ onUploadMethodSwith, setCSVData }: any) => {
  const { fontFamilies } = useContext(ThemeContext);
  const { data: companies } = useGetCompanies();

  const { handleSubmit, control, setValue } = useForm<BulkUploadFomType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!isValidFile(data)) {
      message.info('Unsupported format. Please upload the file in CSV format.');
      return;
    }

    Papa.parse(data[0], {
      error: () => {
        message.info('There was an issue.');
      },
      complete: (result) => {
        setCSVData(result.data);
        onUploadMethodSwith();
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onSubmit });

  useEffect(() => {
    companies?.data[0] &&
      setValue('company_id', companies?.data[0].id.toString());
  }, [companies, setValue]);

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      companies?.data.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [companies],
  );

  return (
    <BulkUserAddStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name={'company_id'}
        label={'Company'}
        as={BaseSelect}
        page={FormPageTypeEnum.addUsers}
        itemsArray={getCompanies}
      />
      <div {...getRootProps()} className={'file-drop'}>
        <Row
          align={'middle'}
          justify={'center'}>
          <DocumentBulkUpload />
          <input id={'file'} {...getInputProps()} />
          <div style={{ textAlign: 'center' }}>
            <Text color={'#53536D'} className={'drop-zone__title'}>
              Bulk upload
            </Text>
            <Text color={'#C1C9D6'}>
              Drop a list of users in CSV format to upload it or click to
              browse to the file
            </Text>
          </div>
        </Row>
      </div>
      <Row
        align={'middle'}
        justify={'center'}
        className={'bulk-upload__footer'}
      >
        <Button
          type={'text'}
          className={'switch-to-manual'}
          onClick={onUploadMethodSwith}
        >
          <Text
            fontSize={18}
            fontFamily={fontFamilies.regular}
            color={'#5B59C2'}
          >
            Switch to Manual Registration
          </Text>
        </Button>

        <Button
          type={'text'}
          className={'bulk-upload__download-csv'}
          icon={<DowloadIcon />}
          href={sampleFile}
          color="transparent"
          target="_blank"
          download={'sample.csv'}
        >
          <Text
            fontSize={18}
            fontFamily={fontFamilies.regular}
            color={'#9D9DDA'}
          >
            Download a Sample CSV
          </Text>
        </Button>
        {/* <BaseSecondaryButton
              title={'Next'}
              className={'btn--fill'}
              backgroundColor={'#53536D52'}
              htmlType={'submit'}
            /> */}
      </Row>
    </BulkUserAddStyled>
  );
};

export default BulkUserAdd;
