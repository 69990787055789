import ajax from './ajax';
import formDataAjax from './formDataAjax';
import useRequest from './useRequest';
import endpoints from './endpoints';

export const addSlide = (
  websiteSlug: string,
  productSlug: string,
  slideObj: SlideObjectType,
) =>
  formDataAjax.post<RegisterResponseType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides`,
    slideObj,
  );

export const updateSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
  slideObj: SlideObjectType,
) =>
  formDataAjax.post<RegisterResponseType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}`,
    slideObj,
  );

export const updateOrder = (
  websiteSlug: string,
  productSlug: string,
  slides: {
    slides: SlideObjectType[];
  },
) => {
  ajax.put(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/order`,
    slides,
  );
};

export const useGetSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
) =>
  useRequest<SlideObjectTypeConfigPage>(
    endpoints.getSlide(websiteSlug, productSlug, slideSlug),
  );

export const useGetSlides = (websiteSlug: string, productSlug: string) => 
  useRequest<SlideObjType>(endpoints.getSlides(websiteSlug, productSlug))

export const deleteSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
) =>
  ajax.delete<DeleteResponseType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}`,
  );

export const expireSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
) =>
  ajax.get(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}/expire`,
  );

export const republishSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
) =>
  ajax.get(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}/republish`,
  );

export const pinSlide = (
  websiteSlug: string,
  productSlug: string,
  slideSlug: string,
) =>
  ajax.get(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}/pinned`,
  );
