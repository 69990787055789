import endpoints from './endpoints';
import useRequest from './useRequest';
import ajax from './ajax';
import { Moment } from 'moment';

export const addWebsite = (
  companySlug: string,
  data: AddWebsiteBulkRequestType,
) =>
  ajax.post<RegisterResponseType>(
    `api/v1/companies/${companySlug}/websites/bulk/create`,
    data,
  );

type WebsiteDetailResponseType = {
  data: WebsiteType;
};

type CompanyWebsitesDetailResponseType = {
  data: any;
};
export const useGetWebsite = (
  companySlug: string,
  websiteSlug: string | undefined,
) =>
  useRequest<WebsiteDetailResponseType>(
    websiteSlug ? endpoints.getWebsite(companySlug, websiteSlug) : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    },
  );

export const getWebsiteWithStat = (
  companySlug: string,
  websiteSlug: string,
  startDate: string | Moment,
  endDate: string | Moment,
) =>
  ajax.get<{ data: WebsiteWithStat }>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/stat?startDate=${startDate}&endDate=${endDate}`,
  );

export const useGetWebsitesOfCompany = (companySlug: string) =>
  useRequest<CompanyWebsitesDetailResponseType>(
    endpoints.getWebsitesOfCompany(companySlug),
    {
      refreshInterval: 0,
    },
  );

export const deleteWebsite = (
  action: string,
  companySlug: string,
  websiteSlug: string,
  inheritedWebsite: string,
) =>
  ajax.delete<AddUserResponseObjectType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}`,
    { data: { action, website_slug: inheritedWebsite } },
  );

export const editWebsite = (
  companySlug: string,
  websiteSlug: string,
  data: EditWebsiteFormType,
) =>
  ajax.put<WebsiteDetailResponseType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}`,
    data,
  );

export const moveWebsite = (
  companySlug: string,
  websiteSlug: string,
  data: MoveWebsiteFormType,
) =>
  ajax.put<AddUserResponseObjectType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/move`,
    data,
  );

type WebsitesDetailResponseType = {
  data: WebsiteType[];
};

export const useGetArchivedWebsites = (companySlug: string) =>
  useRequest<ArchivedWebsitesType>(endpoints.getArchivedWebsites(companySlug));

export const useGetAllWebsites = () =>
  useRequest<WebsitesDetailResponseType>(endpoints.getWebsites, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

export const useGetCompanyWebsitesFullInfo = (companySlug: string) =>
  useRequest<WebsiteFullInfoDataType>(
    endpoints.getWebsitesFullInfo(companySlug),
  );

export const restoreWebsite = (companySlug: string, websiteSlug: string) =>
  ajax.post<MessageResponseType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/restore`,
  );

export const getWebsitesExcept = (slug: string) =>
  useRequest<NameSlugData>(`api/v1/websites/except/${slug}`);

export const listWebsitesMenuTree = (companySlug: string) =>
  ajax.get(`api/v1/ux/tree/companies/${companySlug}/websites`);
