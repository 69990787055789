import React from 'react';
import styled, { css } from 'styled-components';

type TextProps = {
  fontSize?: number;
  color?: string;
  fontFamily?: string;
  noWrap?: boolean;
  lineHeight?: number;
  hover?: boolean;
  fontWeight?: string
  letterSpacing?: string
};

export default React.memo(styled.p<TextProps>`
  ${({
  theme: { colors, fontFamilies },
  fontFamily,
  fontSize,
  lineHeight,
  color,
  noWrap,
  hover,
  fontWeight,
  letterSpacing
}) => css`
    font-size: ${fontSize ? fontSize / 16 : 1}rem;

    font-family: ${fontFamily ? fontFamily : fontFamilies.regular};

    font-weight: ${fontWeight ? fontWeight : 'normal'};

    letter-spacing: ${letterSpacing ? `${letterSpacing} !important` : '0'};

    position: relative;
    letter-spacing: 0px;
    line-height: ${lineHeight
    ? lineHeight / 16
    : (fontSize !== undefined ? fontSize / 16 : 1) * 1.6}rem;

    color: ${color ? color : colors.primaryText};

    font-weight: ${fontFamily && fontFamily === fontFamilies.bold
    ? 'bold'
    : fontFamily === fontFamilies.semiBold
      ? 600
      : 'normal'};

    ${hover &&
  css`
      cursor: pointer;
    `}
    ${noWrap &&
  css`
      white-space: nowrap;
    `}
  `};
`);
