import styled from 'styled-components';

export const StorylineStyled = styled.div`
  cursor: pointer;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-left: 30px;
  position: relative;
  border: 1px solid white;
  transition: 0.2s;

  &:hover {
    border: 1px solid #4b4dc8;
    & .arrow-button {
      svg {
        transition: 0.2s;
        color: #4b4dc8;
      }
    }
  }

  & .arrow-button {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 13%;
    right: 7%;
  }

  .storyline-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .storyline-title{
      overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
    }
    .storyline-logo {
      object-fit: cover;
      border-radius: 10px;
    }
    h3 {
      font-size: 21px;
      font-family: 'Noir Pro Bold';
      margin-left: 10px;
    }
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f3f5;
  }

  .storyline-body {
    padding-top: 20px;

    p {
      font-size: 16px;
      font-family: 'Noir Pro Light';
      margin-bottom: 15px;
      color: #808e9b;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    span {
      margin-left: 10px;
      font-size: 16px;
      font-family: 'Noir Pro Regular';
    }
  }

  .storyline-stats {
    div {
      display: flex;
    }
  }

  @media (max-width: 1366px) {
    .storyline-header {
      h3 {
        font-size: 18px;
      }
    }

    .storyline-body {
      p {
        font-size: 14px;
      }

      span {
        font-size: 14px;
      }
    }
  }
`;

export const StorylineEmptyStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 204px;
  margin-left: 30px;
  background-color: #f6f7fc;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  p {
    font-size: 16px;
    font-family: 'Noir Pro Light';
    color: #808e9b;
  }
`;
