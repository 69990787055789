import React, { FC } from 'react';
import {
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  FlexboxProps,
} from 'styled-system';
import { StyledContainer, StyledSpan } from './styled';

type SystemProps = SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  FlexboxProps;
interface Props extends SystemProps {
  fontSize?: string | number;
  color?: string;
  bg?: string;
  hover?: boolean;
  span?: boolean;
  [x: string]: any;
}

const Text: FC<Props> = ({
  fontSize,
  span = false,
  children,
  hover,
  ...rest
}) => {
  return span ? (
    <StyledSpan hover={hover} fontSize={fontSize} {...rest}>
      {children}
    </StyledSpan>
  ) : (
    <StyledContainer fontSize={fontSize} hover={hover} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default Text;
