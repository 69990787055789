import { ColProps } from 'antd/lib/col';
import { Text } from 'components/styles';
import React from 'react';
import { TableHeadSortableItemStyled } from './TableHeadSortableItem.styled';
import { ReactComponent as SortStatistic } from 'assets/image/svg/sorting-arrows.svg';
import { TableHeadEnum } from 'hooks/useSortStatisticPage';

type TableHeadSortableItemPropsType = {
  text: string;
  onSelect: () => void;
  $active: boolean;
  $sortingOrderIsAsc: boolean;
  type: string;
} & ColProps;
const TableHeadSortableItem = ({
  text,
  onSelect,
  ...props
}: TableHeadSortableItemPropsType) => {
  return (
    <TableHeadSortableItemStyled {...props}>
      <div
        className={`flex flex-row justify-center text-wrapper ${
          props.type === TableHeadEnum.Spot ? 'flex-start' : 'flex-center'
        }`}
        onClick={onSelect}
        style={{ alignItems: 'center' }}
      >
        <Text className="sort-title">
          {text}
        </Text>
        <SortStatistic />
      </div>
    </TableHeadSortableItemStyled>
  );
};

export default TableHeadSortableItem;
