import styled from "styled-components";

export const FeatureBoxStyled = styled.div`
width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    p {
        text-align: center;
        /* width: 60%; */
        color: #181818;
    }
    @media only screen and (max-width: 576px) {
        width: calc(100% + 15px);
    }
    @media only screen and (min-width: 1600px) {
        p {
            width: 80%;
            font-size: 21px;
        }

    }

`