import styled from 'styled-components';

export const BulkUserAddStyled = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  .scrollable {
    height: 100%;
  }
  .bulk-upload__download-csv {
    display: flex;
    align-items: center;
    margin: 30px 0px;
    justify-content: center;
  }
  .bulk-upload__download-csv p {
    margin-left: 20px;
  }

  .bulk-upload__footer {
    flex-direction: row;
    margin-bottom: 30px;
  }

  .switch-to-manual {
    justify-content: center;
  }

`;