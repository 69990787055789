import React from 'react'

const ArrowIcon = (props: React.SVGAttributes<SVGElement>) => {
    return (
        <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            xmlns="http://www.w3.org/2000/svg"
            transform='rotate(180)'
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00122 2.70217L9.971 6.67698C10.2648 6.97095 10.74 6.97095 11.0307 6.67698C11.3245 6.38301 11.3213 5.90766 11.0307 5.61369L6.5326 1.11037C6.24815 0.825784 5.79178 0.819531 5.49796 1.08848L0.96866 5.61057C0.821751 5.75755 0.74985 5.95144 0.74985 6.14222C0.74985 6.33298 0.82174 6.52687 0.96866 6.67386C1.26249 6.96783 1.73761 6.96783 2.02831 6.67386L6.00122 2.70217Z"
            />
        </svg>

    )
}

export default ArrowIcon