import { ajax } from "services"
import * as Api from "../../../api"
import { getStatisticPageWebsiteParams } from "./params"
import { StatisticPageWebsite } from "./types"

export const getStatisticPageWebsite = (params: getStatisticPageWebsiteParams) => {
    return (
        ajax.get<StatisticPageWebsite>(Api.endpoints.statisticPageWebsite(
            params.args.companySlug,
            params.args.websiteSlug,
            params.args.startDate,
            params.args.endDate))
    )
}