import endpoints from './endpoints';
import useRequest from './useRequest';
import ajax from './ajax';
import { Moment } from 'moment';

export const useGetCompanies = () =>
  useRequest<GetCompaniesResponseType>(endpoints.getCompanies, {
    refreshInterval: 0,
  });

export const useGetCompaniesByRole = () =>
  useRequest<GetCompaniesResponseType>(endpoints.getCompaniesByRole, {
    refreshInterval: 0,
  });

type CompanyDetailResponseType = {
  data: CompanyDetailsType;
};

export const useGetCompany = (slug: string) =>
  useRequest<CompanyType>(endpoints.getCompany(slug), {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

export const useGetCompanyWithStat = (
  slug: string,
  startDate: string | Moment,
  endDate: string | Moment,
) =>
  useRequest<{ data: CompanyWithStat }>(
    endpoints.getCompanyWithStat(slug, startDate, endDate),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    },
  );

type CreateCompanyPropsType = {
  name: string;
  address: string;
  country_iso: string;
  region: string;
  postal_code: string;
  city: string;
  logo: string | File;
};

type CreateCompanyResponseType = {
  first_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  last_name: string;
  phone: string;
  stay_logged: boolean;
};

export const createCompany = (data: CreateCompanyPropsType) =>
  ajax.post<CreateCompanyResponseType>('api/v1/companies', data);

export const addUserToCompany = (data: InviteUsersFormType) =>
  ajax.post<AddUserResponseObjectType>('api/v1/company/invite-users', data);

export const deleteCompany = (
  action: string,
  companySlug: string,
  inheritedCompany: string,
) =>
  ajax.delete<{ status: 'success' }>(`api/v1/companies/${companySlug}`, {
    data: { action, company_slug: inheritedCompany },
  });

export const editCompany = (
  companySlug: string,
  data: CreateCompanyPropsType,
) =>
  ajax.post<CompanyDetailResponseType>(`api/v1/companies/${companySlug}`, data);

type GetDeletedCompaniesResponseType = {
  data: CompanyDetailsType[];
};

export const useGetDeletedCompanies = () =>
  useRequest<GetDeletedCompaniesResponseType>(endpoints.getDeletedCompanies, {
    refreshInterval: 0,
  });

export const useGetArchivedCompanies = () =>
  useRequest<GetArchivedCompaniesType>(endpoints.getArchivedCompanies);

export const useGetOwningCompanies = () =>
  useRequest<OwningCompanies>(endpoints.getOwningCompanies);

export const restoreCompany = (companySlug: string) =>
  ajax.post<MessageResponseType>(`api/v1/companies/${companySlug}/restore`);

export const moveCompany = (
  companySlug: string,
  enhiritedEntity: string,
  notes: string,
) =>
  ajax.put<AddUserResponseObjectType>(`api/v1/companies/${companySlug}/move`, {
    company_slug: enhiritedEntity,
    notes,
  });

export const getCompaniesWithWebsites = () =>
  useRequest<CompaniesWithWebsitesBoolean>('api/v1/companies/haswebsites');

export const getCompaniesExcept = (slug: string) =>
  useRequest<NameSlugData>(`api/v1/companies/except/${slug}`);

export const registerCompany = (data: CreateCompanyPropsType) =>
  ajax.post<CreateCompanyResponseType>('api/v1/user/register/company', data);
