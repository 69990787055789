import { Col } from 'antd';
import TextSkeleton from '../../../components/skeletons/TextSkeleton';
import React from 'react';
import { UsersSkeletonStyled } from './UsersSkeletonStyled.styled';

const UsersSkeleton = () => {
  return (
    <>
      <UsersSkeletonStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </UsersSkeletonStyled>
      <UsersSkeletonStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </UsersSkeletonStyled>
      <UsersSkeletonStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </UsersSkeletonStyled>
      <UsersSkeletonStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </UsersSkeletonStyled>
      <UsersSkeletonStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </UsersSkeletonStyled>
    </>
  );
};

export default UsersSkeleton;