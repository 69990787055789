import React from 'react';

const AddIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...props}
    >
      <g
        id="Group_5722"
        data-name="Group 5722"
        transform="translate(-1558 -177)"
      >
        <g
          id="Ellipse_39"
          data-name="Ellipse 39"
          transform="translate(1558 177)"
          fill="#fff"
          strokeWidth="2"
        >
          <circle cx="20" cy="20" r="19" stroke="none" />
          <circle cx="20" cy="20" r="19" fill="none" />
        </g>
        <path
          id="Icon_awesome-plus"
          data-name="Icon awesome-plus"
          d="M12.381,7.488H8.1V3.2a.953.953,0,0,0-.952-.952H6.19a.952.952,0,0,0-.952.952V7.488H.952A.952.952,0,0,0,0,8.44v.952a.952.952,0,0,0,.952.952H5.238v4.286a.953.953,0,0,0,.952.952h.952a.953.953,0,0,0,.952-.952V10.345h4.286a.952.952,0,0,0,.952-.952V8.44A.952.952,0,0,0,12.381,7.488Z"
          transform="translate(1571.333 188.083)"
        />
      </g>
    </svg>
  );
};

export default AddIcon;
