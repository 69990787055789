import styled from 'styled-components';

export const CompanyEditStyled = styled.form`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: initial !important;
  padding-bottom: 2rem;
  .input-container {
    margin: 0.5rem 0;
    .ant-input::placeholder {
      visibility: hidden;
    }
  }
  .logo-section {
    border-bottom: 1px solid #EAECF2;
    padding-bottom: ${(20 / 16)}rem;
    margin-bottom: 1rem;
    img {
      border-radius: 10px;
      width: ${42 / 16}rem;
      height: ${42 / 16}rem;
    }
  }
  .content__form-container {
    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    margin-top: auto;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 20rem;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }
    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .line-loader-container {
      display: none;
    }
    .content__submit-container {
      flex-direction: column-reverse;
      padding-top: 0;
    }
    .content__form-container {
      .ant-row {
        flex-direction: column;
        .ant-col {
          width: 100%;
          min-width: 100%;
          margin: 0.3rem 0rem;
          .ant-row {
            margin: 0.2rem 0rem;
            .ant-row {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
`;
