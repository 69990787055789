import React, { createContext, useState } from 'react';

interface TypesAdvertsPageContext {
  readonly loading: boolean;
  readonly setLoading: (loading: boolean) => void;
  readonly pluginValue: string;
  readonly activeAddsValue: string;
  readonly setPluginValue: (obj: string) => void;
  readonly setActiveAddsValue: (obj: string) => void;
  readonly pluginArr: { label: string; value: string }[];
  readonly activeAddsArr: { label: string; value: string }[];
}

interface Props {
  readonly children: React.ReactNode;
}

const AdvertsPageContext = createContext({} as TypesAdvertsPageContext);

const AdvertsPageProvider = ({ children }: Props) => {
  
  const [loading, setLoading] = useState(false)
  const [pluginValue, setPluginValue] = useState('1')
  const [activeAddsValue, setActiveAddsValue] = useState('1')

  const pluginArr = [
    { label: 'Plugin Header', value: '1' },
    { label: 'Plugin Sidebar', value: '0' }
  ]

  const activeAddsArr = [
    { label: 'On', value: '1' },
    { label: 'Off', value: '0' }
  ]

  return (
    <AdvertsPageContext.Provider
      value={{
        setPluginValue,
        setActiveAddsValue,
        loading,
        pluginValue,
        pluginArr,
        activeAddsValue,
        activeAddsArr,
        setLoading
      }}
    >
      {children}
    </AdvertsPageContext.Provider>
  );
};

export { AdvertsPageContext, AdvertsPageProvider };
