import React, { ChangeEvent } from 'react';

import { ArchiveProductPageStyled } from './ArchiveProductPage.styled';

import { useParams } from 'react-router-dom';
import useSortableTable from '../components/useSortableTable';
import { archiveWebsiteItems } from './const';
import TableComponent from '../components/TableComponent';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import TableComponentLazy from '../components/TableComponentLazy';
import Header from '../components/Header';

enum ProductTableHeadEnum {
  Company = 'Company',
  Product = 'Product',
  Slide = 'Slide',
}

const heads = [
  {
    text: ProductTableHeadEnum.Company,
    span: 7,
    offset: 0,
    type: ProductTableHeadEnum.Company,
  },
  {
    text: ProductTableHeadEnum.Product,
    span: 17,
    offset: 0,
    type: ProductTableHeadEnum.Product,
  },
  {
    text: ProductTableHeadEnum.Slide,
    span: 4,
    offset: 0,
    type: ProductTableHeadEnum.Slide,
  },
];

const setCurrentSortKey = (activeHead: ProductTableHeadEnum) => {
  switch (activeHead) {
    case ProductTableHeadEnum.Slide:
      return 'slide';
    case ProductTableHeadEnum.Product:
      return 'product';
    default:
      return 'slide';
  }
};

const ArchiveProductPage = () => {

  const { activeHead, sortedProductItems, onSelectSort } = useSortableTable<
    ProductTableHeadEnum,
    typeof archiveWebsiteItems[0]
  >({
    setCurrentSortKey,
    items: archiveWebsiteItems,
    initialActiveHead: ProductTableHeadEnum.Slide,
  });

  return (
    <DashboardLayout>
      <ArchiveProductPageStyled>
        <TableComponentLazy
          headerItems={heads}
          // @ts-ignore: Unreachable code error
          bodyItems={sortedProductItems}
          // @ts-ignore: Unreachable code error
          onSelectSort={onSelectSort}
          activeHead={activeHead}
        />
      </ArchiveProductPageStyled>
    </DashboardLayout>
  );
};

export default ArchiveProductPage;
