import React, { useMemo } from 'react';
import { ModalProps } from 'antd/lib/modal';
import Embed from './modalTypes/Embed';
import AddUsers from './modalTypes/AddUsers';
import AddCompanyComponentModal from './modalTypes/AddCompanyComponentModal';
import ActivateCompanyModal from './modalTypes/ActivateCompanyModal';
import ActivateWebsiteModal from './modalTypes/ActivateWebsiteModal';
import AddWebsite from './modalTypes/addWebsite/AddWebsite';
import Remove from './modalTypes/remove';
import ActivateUserModal from './modalTypes/ActivateUserModal';
import AddNewEntityModal from './modalTypes/AddNewEntityModal';
import AddProduct from '../common/addProduct/AddProduct';
import MoveUserModal from './modalTypes/MoveUserModal';
import CompanyEdit from './modalTypes/CompanyEdit';
import WebsiteSettings from './modalTypes/WebsiteSettings';
import StorylineEdit from './modalTypes/StorylineEdit';

export enum ModalTypeEnum {
  Embed,
  addUsers,
  addCompany,
  addWebsite,
  activateCompany,
  activateWebsite,
  restoreUser,
  remove = 7,
  addEntity,
  addProduct,
  moveUser,
  editCompany,
  editWebsite,
  editStoryline,
}

type ModalCustomPropsType = {
  children?: React.ReactNode;
  type: ModalTypeEnum;
  companySlug?: string;
  websiteSlug?: string;
  data:
  | EmbedModalPropsType
  | CommonModalPropsType
  | ActivateCompanyModalPropsType
  | RemoveProductPropsType
  | ActivateWebsiteModalPropsType
  | ActivateUserModalProps
  | AddNewEntityPropsType
  | MoveUserPropsType;
} & ModalProps;

const ModalCustom = ({
  children,
  type,
  data,
  companySlug,
  websiteSlug,
  ...props
}: ModalCustomPropsType): React.ReactElement => {
  const renderChildren = useMemo(() => {
    switch (type) {
      case ModalTypeEnum.Embed:
        return <Embed {...(data as EmbedModalPropsType)} />;
      case ModalTypeEnum.addUsers:
        return <AddUsers {...(data as CommonModalPropsType)} />;
      case ModalTypeEnum.moveUser:
        return <MoveUserModal {...(data as MoveUserPropsType)} />;
      case ModalTypeEnum.addEntity:
        return <AddNewEntityModal {...(data as AddNewEntityPropsType)} />;
      case ModalTypeEnum.addCompany:
        return <AddCompanyComponentModal {...(data as CommonModalPropsType)} />;
      case ModalTypeEnum.activateCompany:
        return <ActivateCompanyModal {...(data as ActivateCompanyModalPropsType)} />;
      case ModalTypeEnum.activateWebsite:
        return <ActivateWebsiteModal {...(data as ActivateWebsiteModalPropsType)} />;
      case ModalTypeEnum.restoreUser:
        return <ActivateUserModal {...(data as ActivateUserModalProps)} />;
      case ModalTypeEnum.addWebsite:
        return <AddWebsite {...(data as AddWebsitePropsType)} />;
      case ModalTypeEnum.addProduct:
        return <AddProduct {...(data as CommonModalPropsType)} />;
      case ModalTypeEnum.remove:
        return <Remove {...(data as RemoveProductPropsType)} />;
      case ModalTypeEnum.editCompany:
        return <CompanyEdit companySlug={companySlug} />;
      case ModalTypeEnum.editWebsite:
        return <WebsiteSettings companySlug={companySlug} websiteSlug={websiteSlug} />;
      case ModalTypeEnum.editStoryline:
        return <StorylineEdit />;
      default:
        return <div> not supported{children}</div>;
    }
  }, [type, data, children]);

  return (
    // <ModalCustomStyled
    //   destroyOnClose
    //   footer={null}
    //   closeIcon={<CloseOutlined className="modal_close_icon" />}
    //   {...props}
    // >
    <div style={props.visible ? { display: 'block', height: '100%' } : { display: 'none' }}>
      {renderChildren}
    </div>
    // </ModalCustomStyled>
  );
};

export default ModalCustom;
