import { AddNewEntity } from 'components/common/userMove';
import React from 'react';
import { ModalWrapperStyled } from './AddCompanyComponentModal.styled';
import { ModalTypeEnum } from 'components/modal/ModalCustom';

type AddNewEntityPropsType = {
  onClose: () => void;
  onChoose: (type: ModalTypeEnum) => void;
};

const AddNewEntityModal = ({
  onClose,
  onChoose,
}: AddNewEntityPropsType): React.ReactElement => {
  return (
    <ModalWrapperStyled>
      <AddNewEntity onChoose={onChoose} onClose={onClose} />
    </ModalWrapperStyled>
  );
};

export default AddNewEntityModal;
