import styled, { css } from 'styled-components';

interface BaseFloatingLabelStyledProps {
  $textAreaType?: boolean
  $characterCount?: boolean
  select?: boolean
}

export const BaseFloatingLabelStyled = styled('div')<BaseFloatingLabelStyledProps>`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  
  .characterLeft{
    margin: 5px 0 0;
    color: #808E9B;
  }

  ${({ $characterCount }) => ($characterCount && css`
  margin-bottom: 0;
  position: unset;
  .label{
    top: 25%!important;
  }
  .label-float{
    top: 20%!important;
  }
  `)}
  ${({ select }) => (select && css`
  .label{
    top: 30%!important;
  }
  .label-float{
    top: 15%!important
  }
  `)}

  .label {
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 32px;
    top: ${({ $textAreaType }) => ($textAreaType ? '20%' : '30%')};
    transition: 0.1s ease all;
    color: rgba(128, 142, 155, 0.6);
  }

  .label-float {
    top: ${({ $textAreaType }) => ($textAreaType ? '15%' : '25%')};
    font-size: 10px;
  }
`;
