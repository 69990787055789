import styled from 'styled-components';


export const FooterStyled = styled.footer`
display: flex;
justify-content: flex-end;
align-items: center;
padding: 0 65px;
flex: 0 0 50px;
  background-color: #fff;
  box-shadow: 0px 0px 12px #11348529;

  span {
      font-size: 12px;
      color: #5C5CC2;
      font-family: Segoe UI Regular;
  }

  @media only screen and (max-width:1000px){
    justify-content: center;
    z-index: 100;
}
`;