import { LandingPageStyled } from './LandingPage.styled';
import React, { useEffect } from 'react';
import { BaseButton } from 'components/baseComponents';
import { Link } from 'react-router-dom';
import {
  Container,
  InnerContainer,
  ContainerGrey,
} from './components/Container.styled';
import { Col, Row, Space } from 'antd';
import images, { svg } from 'assets/image';
import { Text, Title } from 'components/styles';
import PurposeBox from './components/PurposeBox';
import FeatureBox from './components/FeatureBox';
import PublicHeader from 'components/header/PublicHeader';
import { ReactComponent as GreenRectangle } from "../../assets/image/svg/green-rectangle.svg"
import { ReactComponent as ScrollArrow } from "../../assets/image/svg/scroll-arrow.svg"
import { ReactComponent as PurpleHexagon } from "../../assets/image/svg/purple-hexagon.svg"
import { ReactComponent as StoryShareGreenArrow } from "../../assets/image/svg/share-your-story-arrow.svg"
import { ReactComponent as AddStoryIcon } from "../../assets/image/svg/share-your-story-add-icon.svg"

export default function LandingPage() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_WIDGET_SCRIPT_URL!;
    script.dataset.code = process.env.REACT_APP_WIDGET_CODE;
    script.id = "story-creator-widget";
    script.defer = true;
    document.body.appendChild(script);

    // this style needed to display widget in sticky style
    document.getElementsByTagName("body")[0].style.position = "relative";

    return () => {
      document.getElementById("story-creator-widget")?.remove();
      document.getElementsByTagName("body")[0].removeAttribute("style")
    };
  }, [])
  return (
    <>
      <div data-storyhub-widget="true"></div>
      <LandingPageStyled>
        <InnerContainer className="landing_navbar">
          <PublicHeader />
        </InnerContainer>
        <Container>
          {/* <InnerContainer> */}
          <Row gutter={30} className="first-row" justify="space-between">
            <Col
              className="gutter-row left-column"
              lg={{ span: 12, order: 1 }}
              span={24}
              order={2}
            >
              <div className="landing__text-box">
                <Title.H1 fontSize={70} fontWeight="bold" lineHeight={66} color="#181818">
                  <span>Maximize</span> your profit with StoryHub
                </Title.H1>
                <Text fontSize={18} position="inherit" fontFamily="Noir Pro Light" className="scroll-pointer">
                  Digital Story Hub is a responsive web application that allows
                  you to customize, schedule and publish engaging stories on all
                  of your websites from one single platform. You can maximize
                  your profit and increase conversions without editing code.
                </Text>
              </div>
            </Col>
            <Col
              className="gutter-row story1"
              lg={{ span: 12, order: 2 }}
              span={24}
              order={1}
            >
              <div className="see-it-for-yourself">
                <ScrollArrow />
                <span className="scroll-text">See it for<br />Yourself!</span>
              </div>
              <img src={images.Story1} alt="Story-example" />
            </Col>
          </Row>
          {/* </InnerContainer> */}
          <ContainerGrey className="purpose">
            <GreenRectangle className="green-rectangle" />
            <Title.H2 fontSize={36} color="#181818">
              Why <span>StoryHub</span>?
            </Title.H2>
            <Text className="slogan1" fontFamily="Noir Pro Light" fontSize={18} color="#181818">
              You can now create powerful stories and simplify your website
              management without having to modify the code. Story Hub comes with a
              simple and easy to use interface and works on all devices, providing
              a fully responsive design.
              <span className="useful">Useful to know</span>
            </Text>
            <Space>
              {[
                { icon: svg.Picon1, title: 'Maximize your website profits' },
                { icon: svg.Picon2, title: 'Plan you stories ahead' },
                { icon: svg.Picon3, title: 'Bulk upload of sub-users' },
                { icon: svg.Picon4, title: 'Efficient and easy to set up' },
                { icon: svg.Picon5, title: 'No coding required' },
              ].map((item, index) => (
                <PurposeBox key={index} title={item.title} icon={item.icon} />
              ))}
            </Space>
          </ContainerGrey>
          <InnerContainer className="about-storyhub">
            <Row gutter={30} className="reverse-on-mobile">
              <Col
                lg={{ span: 12, order: 1 }}
                span={24}
                className="gutter-row story2 purple-plus-div"
              >
                <img src={images.Story2} alt="Story-example" />
              </Col>

              <Col className="title-main" lg={{ span: 12, order: 2 }} span={24}>
                <div className="LandingPage__text-box slogan2">
                  <Title.H1 fontSize={70} fontWeight="bold" color="#181818">
                    <span className="reach-goals">Reach your marketing goals faster than ever!</span>
                    About Storyhub
                  </Title.H1>
                  <Text fontFamily="Noir Pro Light" fontSize={18}>
                    The easy to use Digital Story Hub comes in really handy for
                    organizations that wish to constantly expand company’s social
                    presence and showcase their portfolio to new and existing
                    clients. Not only is it a few-steps-registration process, it
                    also is simple to use once installed on your website.
                  </Text>
                  <Text fontFamily="Noir Pro Light" fontSize={18}>
                    It has never been more convenient for businesses to advertise
                    products on their many websites and set goals for each
                    campaign and each website. The purpose of it all? You decide
                    how to manage all your powerful stories in one click.
                  </Text>
                  <Link to="/register">
                    {' '}
                    <BaseButton text="Try for free" className="try-for-free" backgroundColor="#4B4DC8" />
                  </Link>
                </div>
              </Col>
            </Row>
          </InnerContainer>
          <ContainerGrey className="orange-plus">
            <Title.H2 fontSize={36} color="#181818">
              Achieve your <br /> <span>business</span> objectives
            </Title.H2>
            <Row gutter={[15, 15]}>
              {[
                {
                  color: '#4B4DC8',
                  text: 'Functionally organize your story slides in a few simple steps',
                },
                {
                  color: '#EF5777',
                  text: 'Combine all your actions across all websites with one single tab',
                },
                {
                  color: '#FFDD59',
                  text: 'The slowdown of manually choosing every single story is long gone',
                },
                {
                  color: '#4BCFFA',
                  text: 'Whether you are a novice or a professional, with Digital Story Hub you will soon master all your website story management',
                },
              ].map((item, index) => {
                return (
                  <Col lg={12} span={24} key={index}>
                    <div
                      style={{ borderColor: item.color }}
                      className="bordered-text"
                    >
                      <Text color="#181818" fontSize={21}>{item.text}</Text>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </ContainerGrey>
          <InnerContainer className="features">
            <Title.H1 fontSize={70} fontWeight="bold" className="slogan3" color="#181818">
              <span>Key</span> features
              <span className="love-it">You will love it</span>
            </Title.H1>
            <Row gutter={30}>
              <Col className="gutter-row" lg={17} span={24}>
                <Text fontSize={18} fontFamily="Noir Pro Light">
                  This new innovation will improve business product display rate
                  and audience engagement, not only throughout social media but on
                  the company’s website as well. The overall business performance
                  will spike as the plugin gives you a set of free advantages
                  among competitors and will determine your company’s online
                  presence.
                </Text>
              </Col>
            </Row>
            <Row gutter={30}>
              {[
                { icon: svg.Ficon1, title: 'One simple view' },
                {
                  icon: svg.Ficon2,
                  title: 'Manage all websites from a single panel',
                },
                {
                  icon: svg.Ficon3,
                  title: 'Multiple sub-user and hierarchy access',
                },
                {
                  icon: svg.Ficon4,
                  title: 'Pick business structure that fits you best',
                },
                {
                  icon: svg.Ficon5,
                  title:
                    'Add business elements such as products, websites and companies',
                },
                { icon: svg.Ficon6, title: 'Easy switch to bulk upload' },
              ].map((item, index) => (
                <Col key={index} className="gutter-row" md={8} span={12}>
                  <FeatureBox title={item.title} icon={item.icon} />
                </Col>
              ))}
            </Row>
            <Link to="/register">
              <BaseButton text="Get it Free" className="try-for-free" backgroundColor="#4B4DC8" />
            </Link>
          </InnerContainer>
          <ContainerGrey className="tutorial">
            <GreenRectangle className="green-rectangle" />
            <Title.H2 fontSize={34} color="#181818">
              Share your <span>Story</span>
            </Title.H2>
            <Text fontFamily="Noir Pro Light" color="#181818">
              Digital Story Hub allows you to easily manage, analyze and monitor stories on multiple websites across all your companies from a single portal! It only takes 3 simple steps to set up the widget: Add multiple companies, attach the respective website, add the storylines and you are ready to go!
            </Text>
            {/* <img src={images.tutorial} alt="tutorial" /> */}
            <div className="share-your-story">
              <div className='add-box add-company'>
                <img src={images.AddCompany} alt="Add Company" />
                <span>
                  <AddStoryIcon />Add<br />Company</span>
              </div>
              <div className='add-box-between'>
                <StoryShareGreenArrow />
              </div>
              <div className='add-box add-site'>
                <img src={images.AddSite} alt="Add Site" />
                <span><AddStoryIcon />Add<br />Website</span>
              </div>
              <div className='add-box-between second'>
                <StoryShareGreenArrow />
              </div>
              <div className='add-box add-storyline'>
                <img src={images.AddStoryline} alt="Add Storyline" />
                <span><AddStoryIcon />Add<br />Storyline</span>
              </div>
            </div>
          </ContainerGrey>
          <InnerContainer className="options">
            <Title.H1 fontSize={70} fontWeight="bold" className="slogan4" color="#181818">
              Customisation <span>options</span>
              <span className="wow">wow</span>
            </Title.H1>
            <Row gutter={30}>
              <Col
                className="gutter-row"
                lg={{ span: 6, order: 1 }}
                xs={{ span: 24, order: 2 }}
              // sm={{ span: 12, order: 2 }}
              >
                <Text fontSize={18}>
                  <span>

                    <span className="options-title">Badge Shape</span>
                    <Text fontSize={18}>Choose between rectangular and round</Text>
                  </span>
                  <span>
                    <span className="options-title">Pin Option</span>
                    <Text fontSize={18}>Pin the badge on the screen or let it move while scrolling</Text>
                  </span>
                  <span>
                    <span className="options-title">Desired Pages</span>
                    <Text fontSize={18}>Choose on which pages you want to see your story</Text>
                  </span>
                </Text>
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 12, order: 2 }}
                xs={{ span: 24, order: 1 }}
              >
                <img src={images.laptop} alt="laptop" />
              </Col>
              <Col
                className="gutter-row"
                lg={{ span: 6, order: 3 }}
                xs={{ span: 24, order: 3 }}
              // sm={{ span: 12, order: 3 }}
              >
                <Text fontSize={18}>
                  <span>
                    <span className="options-title">Badge Color</span>
                    <Text fontSize={18}>Different colors available for background, border and text color</Text>
                  </span>
                  <span>
                    <span className="options-title">Story Duration</span>
                    <Text fontSize={18}>Make it last up to 10 days. From 5 to 30 seconds</Text>
                  </span>
                  <span>
                    <span className="options-title">Badge Position</span>
                    <Text fontSize={18}>6 Available positions</Text>
                  </span>
                </Text>
              </Col>
            </Row>
          </InnerContainer>
          <div className="blocks">
            <Row gutter={[30, 30]}>
              <Col className="gutter-row left-block" md={12} span={24}>
                <GreenRectangle className="green-rectangle" />
                <img src={images.leftBlock} alt="" />
                <div className="text-box">
                  <Title.H2 fontSize={36} color="white" className="green-tick">
                    How to install the widget
                  </Title.H2>
                  <Text color="white">
                    Once you create an account with Digital Story Hub all you have
                    to do is be patient while we work our magic and have your new
                    tool set up on your website.
                  </Text>
                </div>
              </Col>
              <Col className="gutter-row right-block" md={12} span={24}>
                <PurpleHexagon className="purple-hexagon" />
                <img src={images.rightBlock} alt="" />
                <div className="text-box">
                  <Title.H2 fontSize={36} color="white">
                    Ready to take your business to the next level?
                  </Title.H2>
                  <Text color="white">Let{"'"}s get started</Text>
                  <Link to="/register">
                    <BaseButton text="Start for Free" className="try-for-free" backgroundColor="#4B4DC8" />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <InnerContainer style={{ marginBottom: '0px' }}>
            <footer>
              <img src={svg.copyright} alt="copyright" />
            </footer>
          </InnerContainer>
        </Container>
      </LandingPageStyled>
    </>
  );
}
