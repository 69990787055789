import React, { useState, useCallback, useEffect } from 'react';
import { BaseBadgeColorsStyled, ColorsShapeStyled } from './BaseBadgeColors.styled';
import { Text } from 'components/styles';
import { Divider, Popover } from 'antd';
import { ChromePicker, ColorResult } from 'react-color';

type BaseBadgeColorsProps = {
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (val: string) => void;
  itemsArray?: {
    label: string;
    value: string;
    tooltipText?: string;
  }[];
  text?: string;
};

const BaseBadgeColors = ({
  itemsArray,
  onChange: onChangeVal,
  value: valuesString,
  text,
}: BaseBadgeColorsProps) => {
  const [storyColorComponent, setStoryColorComponent] = useState(false);

  const [colors, setColors] = useState(
    storyColorComponent ? valuesString?.split('') : valuesString?.split('&'),
  );

  useEffect(() => {
    if (!valuesString) return;
    if (storyColorComponent) setColors([valuesString]);
    else setColors(valuesString.split('&'));
  }, [valuesString]);

  if (!colors) {
    setColors(Array(itemsArray?.length ?? 0).fill(''));
  }

  //TODO this is a quick fix to make this component give out initial value
  useEffect(() => {
    if (!valuesString?.includes('&')) setStoryColorComponent(true);
    else setStoryColorComponent(false);
  }, [colors]);

  const onColorChange = useCallback(
    (index: number, val: ColorResult) => {
      if (!colors) return;

      colors[index] = val.hex;

      onChangeVal?.(colors.join('&'));
    },
    [colors],
  );

  const onChangeComplete = useCallback(() => {
    if (!valuesString?.includes('&')) {
      onChangeVal?.(colors?.join() ?? '');
    } else {
      onChangeVal?.(colors?.join('&') ?? '');
    }
  }, [colors]);

  return (
    <BaseBadgeColorsStyled>
      <span
        style={{
          fontSize: '1.125rem',
          fontFamily: 'Noir Pro Regular',
          marginRight: '15px',
          lineHeight: '2',
        }}
      >
        {text}
      </span>
      {itemsArray?.map(({ label, tooltipText }, index) => (
        <Popover
          title={tooltipText}
          key={index}
          className={'flex flex-row flex-center'}
          content={
            <ChromePicker
              color={colors?.[index]}
              onChange={onColorChange.bind(BaseBadgeColors, index)}
              onChangeComplete={onChangeComplete}
              disableAlpha={true}
            />
          }
        >
          <div
            style={{
              background: 'white',
              borderRadius: '10px',
              border: '1px solid #E6E6EA',
              paddingLeft: '10px',
              marginRight: '10px',
            }}
          >
            <ColorsShapeStyled className={`${label}`} key={`${label}`} color={colors?.[index] ?? ''} />
            <Text>{label}</Text>
          </div>
        </Popover>
      ))}
      <Divider />
    </BaseBadgeColorsStyled>
  );
};

export default BaseBadgeColors;
