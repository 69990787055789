import styled from 'styled-components';

export const ArchivePageStyled = styled.div`
  .scrollable {
    overflow-y: auto;
    height: calc(84vh - 16rem);
    padding-bottom: 1.25rem;
    &::-webkit-scrollbar {
      width: 1.375rem !important;
      height: 1.125rem !important;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.5rem solid rgba(0, 0, 0, 0);
    }
  }
`;


// max-height: 100%;
//   height: 100%;
//   overflow: hidden;