import React, { useContext } from 'react';
import { Button, Popover, Typography } from 'antd';
import {
  HeaderAccountStyled,
  AccountPopoverStyled,
} from './HeaderAccount.styled';
import avatarUrl from 'assets/image/svg/avatar.svg';
import arrowHeader from 'assets/image/svg/arrow-header.svg';
import { logOut } from 'utils/helpers/functions';
import { AppContext } from 'state/appContext';
import { appActions } from 'state/appReducer';
import { useHistory } from 'react-router-dom';
const { Text } = Typography;

const HeaderAccount = (): React.ReactElement => {
  const history = useHistory();
  const { dispatch, state: { user } } = useContext(AppContext);

  const onLogOut = () => {
    logOut();
    dispatch(appActions.toggleUserLoginStatus(false));
  };

  return (
    <HeaderAccountStyled align={'middle'} justify={'end'}>
      <Popover
        content={() => (
          <AccountPopoverStyled>
            <ul>
              <li>
                <Button
                  onClick={() => {
                    history.push('/user/profile');
                  }}
                  type={'text'}
                >
                  My profile
                </Button>
              </li>
              <li>
                <a href="/files/Privacy-Policy.pdf" target="_blank">
                  <Button className="pdf-link">Privacy Policy</Button>
                </a>
              </li>
              <li>
                <a href="/files/Terms-of-use.pdf" target="_blank">
                  <Button className="pdf-link">Terms & Conditions</Button>
                </a>
              </li>
              <li>
                <Button onClick={onLogOut} type={'text'}>
                  Log out
                </Button>
              </li>
            </ul>
          </AccountPopoverStyled>
        )}
        className={'flex flex-row flex-center pop-over'}
        placement="bottomRight"
      >
        <img
          className="user_avatar"
          src={user?.avatar ? user.avatar : avatarUrl}
        />
        <Text className={'pop-over__text'}>
          {user?.first_name} {user?.last_name}
        </Text>
        <img src={arrowHeader} />
      </Popover>
    </HeaderAccountStyled>
  );
};

export default HeaderAccount;
