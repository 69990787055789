import React from 'react';
import BaseContentHeader from 'components/baseComponents/BaseContentHeader';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import { DocumentPageStyled } from './DocumentPage.styled';

export default function TermsAndConditions() {
  return (
    <DashboardLayout>
      <DocumentPageStyled>
        <BaseContentHeader>
          <h1>Terms of Use</h1>
        </BaseContentHeader>
        <p>
          <strong>Last updated Sep2021</strong>{' '}
        </p>
        <ul>
          <li>
            &quot;FRT&quot; shall mean Flat Rock Technology Ltd, a company
            incorporated in England and Wales (registration number 6461660)
            having its registered office at 5 St John&rsquo;s Lane, London EC1M
            4BH.
          </li>
          <li>
            &quot;User(s)&quot; means the individual(s) using the products or
            services and are also referred to as &ldquo;You&rdquo; in the Terms.
          </li>
          <li>
            &quot;Story Hub Service&quot; means the Service developed and
            offered by FRT to Users that acts as a localised system for website
            management. The function of Story Hub is to be used for the creation
            of customizable stories (slides) as a client-based promotions
            service.
          </li>
        </ul>
        <p>
          These Terms of Service, including governing your access to and use of
          the FRT Story Hub Service. BY ACCESSING OR USING THE SERVICE, OR BY
          POSTING OR ACCESSING ANY CONTENT ON THE SERVICE, YOU ARE INDICATING
          THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS OF
          SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, THEN YOU HAVE
          NO RIGHT TO ACCESS OR USE THE SERVICE.&nbsp;
        </p>
        <h2>1. Modification</h2>
        <p>
          FRT reserves the right, at its sole discretion, to modify, discontinue
          or terminate the Service at any time. We may also modify these Terms
          of Service so please refer to the &ldquo;Last Updated Date&rdquo; in
          these Terms of Service. By continuing to access or use the Service
          after we have posted a modification to these Terms of Service, you are
          indicating that you agree to be bound by the modified Terms of
          Service. If the modified Terms of Service are not acceptable to you,
          your only recourse is to cease using the Service.&nbsp;
        </p>
        <h2>2. Service Content and Ownership</h2>
        <p>
          Certain types of content are made available through the Service.
          &ldquo;Story Hub&rdquo; means the content, including without
          limitation the FRT trademarks or any third party trademarks,
          technology, and copyrighted works, made available through the Service,
          which are owned by or licensed to FRT. The Story Hub is protected by
          copyright, trademark, and other laws of the United Kingdom and foreign
          countries. Except as expressly provided in these Terms of Service, FRT
          exclusively own all right, title and interest in and to the Service,
          including all associated intellectual property rights. You agree not
          to remove, alter or obscure any copyright, trademark, service mark or
          other proprietary rights notices incorporated in or accompanying the
          Service or FRT Content.&nbsp;
        </p>
        <p>
          Users who are using the free version of Story Hub, in any part of the
          Service, the Content that they access, including its selection and
          placement, may be influenced by commercial considerations, including
          FRT agreements with third parties. Some Content licensed by, provided
          to, created by, or otherwise made available by FRT may incorporate
          advertising or other promotional messages.
        </p>
        <ul>
          <li>
            FRT shall not be liable for any consequential financial loss or
            damage however caused as a result of system failure.
          </li>
          <li>
            FRT would not support User during the installation or configuration
            of the Story Hub on their device.
          </li>
          <li>
            In the event of strikes, Acts of God, War or any other cause of
            events, which prevent FRT from carrying out this Service, FRT shall
            not be liable for any losses or damages suffered by the User.
          </li>
          <li>
            The provision of Antivirus and Firewall software / hardware remains
            the responsibility of the User.
          </li>
          <li>
            FRT will not be liable for any loss or damage caused by a
            distributed denial-of service attack, viruses or other
            technologically harmful material that may infect your computer
            equipment, computer programs, data or other proprietary material due
            to your use of any Website or to your downloading of any material
            from Web or own media hardware.
          </li>
        </ul>
        <p>
          FRT reserves the right to terminate immediately and without further
          notice any user’s website containing illegal or inappropriate content
          such as:
        </p>
        <ul>
          <li>Adult & Sexual Content</li>
          <li>Arms and Ammunition</li>
          <li>Crime or/and Human Rights Violations</li>
          <li>Death injury or Military Conflict</li>
          <li>Terrorism</li>
          <li>Online Piracy</li>
          <li>Hate speech</li>
          <li>Spam</li>
          <li>Illegal drugs/alcohol/tobacco products</li>
        </ul>
        <h2>3. Invoicing and Payment</h2>
        <ul>
          <li>
            The prices quoted for the paid ad-free version of the Service are in
            respect of a paid monthly subscription fee. No ad-free service will
            be provided whilst a payment is outstanding.
          </li>
          <li>
            All charges stated in or in relation to the Service are inclusive of
            VAT.
          </li>
          <li>
            Refunds – User is allowed on full or partial refund in case of
            receiving faulty Service. User needs to provide FRT with clearly
            evidenced documentation proving that the product or service have not
            been working as expected initially and not after User’s
            interference.
          </li>
        </ul>
        <h2>4. Patents and Copyrights</h2>
        <ul>
          <li>
            These Terms of Service do not vary the terms of any other license
            entered into by the User relating to any software product (including
            software supplied or issued by FRT).
          </li>
          <li>
            You may not copy, modify, distribute, sell, or lease any part of our
            service or software. Also, you may not reverse engineer or attempt
            to extract any of our source code unless you have our written
            permission or applicable law lets you do so.
          </li>
        </ul>
        <h2>5. Data security/Recovery</h2>
        <p>
          FRT will not be held responsible for any data corruption or loss,
          howsoever caused. Should any such data loss occur, FRT will make every
          effort to recover the data.{' '}
        </p>
        <h2>6. Confidentiality</h2>
        <p>
          Neither FRT or any of its representatives or customers will, without
          the other&rsquo;s written consent, disclose to any third party any
          information concerning the business or method of working of the other
          party which may be revealed to it during the period of the Service or
          otherwise, except as required by law or to the extent that such
          information may become public knowledge or may be acquired or
          generated by either party independently from the other otherwise than
          by reason of a breach of this clause.&nbsp;
        </p>
        <h2>7. Limitation of liability</h2>
        <ul style={{ paddingBottom: '0px', marginBottom: '0px' }}>
          <li>
            FRT is not responsible for the loss of User data. The User is
            ultimately responsible for ensuring the appropriate backups are
            completed and that tapes are taken off site or otherwise secured
            appropriately.
          </li>
          <li>
            The liability of FRT for any loss or damage of whatsoever nature and
            howsoever caused shall be limited to and in no circumstances shall
            exceed the aggregate price paid for the service up to the date in
            question.
          </li>
          <li>
            In no event shall FRT, it&rsquo;s partners or suppliers be liable
            for costs of substitute goods or services, nor will they be liable
            for loss of profits, loss of data or any indirect, special,
            incidental, consequential or punitive damages however caused,
            whether due to a breach of contract, negligence or otherwise unless
            such liability is determined by a Court of competent jurisdiction,
            without further recourse to appeal, that it was caused by gross
            negligence, willful misconduct or fraudulent acts, by FRT.
          </li>
          <li>The User indemnifies FRT in respect of:</li>
        </ul>
        <p>
          a) direct damage to FRT&rsquo;s property which is established to be
          the result of negligence by the User or its servants or agents.
        </p>
        <p>
          b) direct injury to or death of any of FRT&rsquo;s servants or agents
          resulting from the negligence of FRT&rsquo;s or its servants or
          agents.
        </p>
        <p>
          c) in no event shall FRT be liable for indirect or consequential
          damages
        </p>
        <h2>8. Indemnity</h2>
        <p>
          You agree to defend, indemnify, and hold FRT, its officers, directors,
          employees, agents, and third party licensors, harmless from and
          against any claims, liabilities, losses, damages, losses, and
          expenses, including, without limitation, reasonable legal and
          accounting fees, arising out of or in any way connected with your
          access to or use of the Service or FRT Content, or your violation of
          these Terms of Service.
        </p>
        <h2>9. Trademark Rights</h2>
        <p>
          The trademarks &ldquo;Flat Rock Technology Ltd&rdquo; and accompanying
          logos are trademarks or registered trademarks of Flat Rock Technology.
          Any other trademarks, service marks, logos, trade names and any other
          proprietary designations are the trademarks or registered trademarks
          of their respective parties.&nbsp;
        </p>
        <h2>10. Assignment</h2>
        <p>
          You may not assign or transfer these Terms of Service, by operation of
          law or otherwise, without FRT&rsquo;s prior written consent. FRT may
          freely assign these Terms of Service.&nbsp;
        </p>
        <h2>11. Governing Law</h2>
        <p>
          The Terms of Service and any disputes or claims arising out of or in
          connection with its subject matter are governed by and construed in
          accordance with the law of England and Wales. The parties irrevocably
          agree that the courts of England and Wales have exclusive jurisdiction
          to settle any dispute or claim that arises out of or in connection
          with the Service.&nbsp;
        </p>
        <h2>12. Our details</h2>
        <p>
          Our business&rsquo;s name is: Flat Rock Technology Limited <br />
          Our business address is: 5 St John&apos;s Lane, London, EC1M 4BH,
          United Kingdom <br />
          Our VAT registration is: No. 6461660 <br />
          Our contact email is: info@flatrocktech.com&nbsp;
        </p>
      </DocumentPageStyled>
    </DashboardLayout>
  );
}
