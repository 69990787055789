import React from 'react';

import { ProductContainerContextProvider } from 'state/productContainer/state/context';
import { AdvertsPageProvider } from 'state/productContainer/advertsPage/advertsPageContext';
import { ListPageProvider } from 'state/productContainer/listPage/listPageContext';
import { HomePageProvider } from 'state/productContainer/homePage/homePageContext';
import { SettingPageProvider } from 'state/productContainer/settingPage/settingPageContext';
import { SlideConfigPageProvider } from 'state/productContainer/slideConfigPage/slideConfigPage';

import { ProductContainerWrapperStyled } from '../../ProductContainerWrapper.styled';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';

import {
    ListPageLazy,
} from '../../containers';

const listPageWrapper = (): React.ReactElement => {
    return (
        <>
            <ProductContainerContextProvider>
                <SlideConfigPageProvider>
                    <SettingPageProvider>
                        <DashboardLayout>
                            <HomePageProvider>
                                <ListPageProvider>
                                    <AdvertsPageProvider>
                                        <ProductContainerWrapperStyled>
                                            <ListPageLazy />
                                        </ProductContainerWrapperStyled>
                                    </AdvertsPageProvider>
                                </ListPageProvider>
                            </HomePageProvider>
                        </DashboardLayout>
                    </SettingPageProvider>
                </SlideConfigPageProvider>
            </ProductContainerContextProvider>
        </>
    );
};

export default listPageWrapper;