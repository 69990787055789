import React from 'react';
import { lazyLoadable } from 'utils';

export const RegistrationPageLazy = lazyLoadable(
  () => import('./RegistrationPage'),
  // TODO: needs skeleton
  <>
    {/* <HomePageTableHead /> */}
  </>,
);
