import { useContext, useEffect, useMemo, useState } from 'react';
import * as Api from 'api';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeContext } from 'styled-components';
import { AppContext } from 'state/appContext';
import {
  useGetCompanyWebsitesFullInfo,
  addProduct,
  endpoints,
  ajax,
} from 'services';
import { validateAxiosErrorInForms } from '../../../utils/helpers/functions';
import { trigger } from 'swr';
import { message } from 'antd';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { StatisticPageWebsiteContext } from 'state/productContainer/statisticPage/website/statisticPageWebsiteContext';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

type seAddCompanyPagePropType = {
  fromModal?: boolean;
  onClose: () => void;
  firstCompany?: boolean;
};

const useAddCompanyPage = () => {
  const { state } = useContext(AppContext);
  const { siderActions } = useContext(SiderNavigationContext);
  const { fontFamilies } = useContext(ThemeContext);
  const [companySlug, setCompanySlug] = useState<string>('');
  const { data: websites } = useGetCompanyWebsitesFullInfo(companySlug);
  const {
    range: rangeWebsite,
    setWebsite,
    website: websiteData,
  } = useContext(StatisticPageWebsiteContext);
  const { onClose } = useContext(BaseRightDrawerContext);

  const oneOfWebsites = websites?.data.map(({ slug }) => slug) ?? [];
  const schema = Yup.object().shape({
    company: Yup.string().required('Please choose a valid company'),
    website: Yup.string()
      .required('Please choose a valid website')
      .oneOf(oneOfWebsites!),
  });
  const {
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<AddProductFormType>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      product: '1',
    },
  });

  const watchFields = watch(['company', 'website']);

  useEffect(() => {
    setCompanySlug(watchFields.company);
    if (companySlug) {
      setError('website', {
        type: 'required',
        message: 'Please choose a valid website',
      });
    }
  }, [watchFields.company]);

  useEffect(() => {
    clearErrors('website');
  }, [watchFields.website]);

  const onSubmit = async ({
    product,
    website,
    company,
  }: AddProductFormType) => {
    try {
      await addProduct(company, website, Number(product));
      onClose();
      trigger(endpoints.siderTree);
      ajax
        .get(Api.endpoints.siderAllProducts(company, website))
        .then((response) => {
          siderActions.setProducts(response.data, website);
        });
      Api.getStatisticPageWebsite({
        args: {
          companySlug,
          websiteSlug: website,
          startDate: rangeWebsite.range.start,
          endDate: rangeWebsite.range.end,
        },
      }).then((res) => {
        setWebsite({
          ...websiteData,
          isLoading: false,
          data: res.data,
        });
      });
      onClose();
      message.success('Product has been added');
    } catch (error) {
      validateAxiosErrorInForms<AddProductFormType>(error, setError);
    }
  };

  const getWebsites: SelectItemType[] = useMemo(
    () =>
      websites?.data.map(({ name, slug }: NameSlugType) => ({
        label: name,
        value: slug,
      })) ?? [],
    [websites],
  );

  return {
    user: state.user,
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    watch,
    setValue,
    getWebsites,
  };
};

export default useAddCompanyPage;
