import React, { useState } from 'react';

import { UserAddStyled } from './UserAdd.styled';
import TopTitleAndSelector from './components/TopTitleAndSelector';
import UserAddForm from './components/UserAddForm';
import BulkUserAdd from './components/BulkUserAdd';

type UserAddPropsType = {
  fromModal?: boolean;
  onClose?: () => void;
};

const UserAdd = ({
  fromModal,
  onClose,
}: UserAddPropsType): React.ReactElement => {
  const [bulkAction, setBulkAction] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [CSVData, setCSVData] = useState([]);

  const onUploadMethodSwith = () => {
    setBulkAction(!bulkAction);
  };

  return (
    <UserAddStyled>
      <TopTitleAndSelector usersCount={usersCount} />
      <div style={{ display: bulkAction ? 'block' : 'none' }}>
        <BulkUserAdd
          onUploadMethodSwith={onUploadMethodSwith}
          setCSVData={setCSVData}
        />
      </div>
      <div style={{ display: bulkAction ? 'none' : 'block' }}>
        <UserAddForm
          onUploadMethodSwith={onUploadMethodSwith}
          setUsersCount={setUsersCount}
          fromModal={fromModal}
          onClose={onClose}
          CSVData={CSVData}
        />
      </div>
    </UserAddStyled>
  );
};

export default UserAdd;
