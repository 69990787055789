import React, { useContext } from 'react';
import { Text, Title } from 'components/styles';
import { ThemeContext } from 'styled-components';
import BaseNotice from 'components/baseComponents/BaseNotice';
import { EmbedStyled } from './Embed.styled';
import BaseCopyable from 'components/baseComponents/BaseCopyable';

const Embed = ({ value }: EmbedModalPropsType): React.ReactElement => {
  const { colors } = useContext(ThemeContext);

  return (
    <EmbedStyled>
      {value ? (
        <>
          <Title.H2 color={colors.primaryColor}>Plugin Script</Title.H2>
          <Text color={'#7D8CA2'} className={'description'}>
            Copy and paste the tag below in the header of your Website
          </Text>
          <BaseNotice />
          <BaseCopyable value={value} />
        </>
      ) : (
        <>
          <Title.H2 color={colors.primaryColor}>Plugin Script</Title.H2>
          <Text color={'#7D8CA2'} className={'description'}>
            Please consent that both adds.txt and sellers.json are uploaded to
            the publisher website by ticking the options.
          </Text>
        </>
      )}
    </EmbedStyled>
  );
};

export default Embed;
