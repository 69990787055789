import { Col,Row } from 'antd';
import styled, { css } from 'styled-components';

export const TableHeadSettingsItemStyled = styled.div`
  .text-wrapper {
    cursor: default;
    p {
      color: #181818;
      margin-right: 0.4rem;
    }
    svg path {
      fill: #808E9B;
    }
  }
`;
