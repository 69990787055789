import { IStatisticPageWebsiteContext, StatisticPageWebsiteContextProps } from "api/statisticPage/website/types"
import moment from "moment"
import React, { createContext, useState } from "react"

const StatisticPageWebsiteContext = createContext({} as IStatisticPageWebsiteContext)

const StatisticPageWebsiteProvider = (props: StatisticPageWebsiteContextProps) => {
  // State
  const [website, setWebsite] = useState<IStatisticPageWebsiteContext["website"]>({ isLoading: true })
  const [range, setRange] = useState<IStatisticPageWebsiteContext["range"]>({
    range: {
      start: moment(new Date()).subtract('days', 7).format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
    },
  })

  const { children } = props

  return (
    <StatisticPageWebsiteContext.Provider
      value={{
        range,
        setRange,
        website,
        setWebsite,
      }}
    >
      {children}
    </StatisticPageWebsiteContext.Provider>
  )
}

export { StatisticPageWebsiteContext, StatisticPageWebsiteProvider }