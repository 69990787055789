import { PluginItemShowTypeEnum } from 'utils/enums';

export default {
  productOrCompanyOrWebsite: '/:type(company|website|product)',
  home: '/home',
  productWithSlugAndSpecificShowTypeRoute: `/company/:companySlug/:websiteSlug/:productSlug/:showType(${PluginItemShowTypeEnum.List}|${PluginItemShowTypeEnum.Settings}|${PluginItemShowTypeEnum.Embed}|${PluginItemShowTypeEnum.Square}|${PluginItemShowTypeEnum.Adverts}|${PluginItemShowTypeEnum.AddNew}|${PluginItemShowTypeEnum.ID})`,
  productSlideListRoute: `/company/:companySlug/:websiteSlug/:productSlug/${PluginItemShowTypeEnum.List}`,
  productSetupRoute: `/company/:companySlug/:websiteSlug/:productSlug/${PluginItemShowTypeEnum.Settings}`,
  productEmbedRoute: `/company/:companySlug/:websiteSlug/:productSlug/${PluginItemShowTypeEnum.Embed}`,
  productAdvertsRoute: `/company/:companySlug/:websiteSlug/:productSlug/${PluginItemShowTypeEnum.Adverts}`,
  productAddRoute: `/company/:companySlug/:websiteSlug/:productSlug/${PluginItemShowTypeEnum.AddNew}`,
  productSlideItemRoute: `/company/:companySlug/:websiteSlug/:productSlug/slide/:slideSlug`,

  websiteNameOrCompanyStatisticRoute: `/company/:companySlug/:websiteSlug?`,

  //products
  toProductSlidesListById: (
    companySlug = '',
    websiteSlug = '',
    productSlug = '',
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.List}`,

  toProductSlidesSquareById: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.Square}`,

  toProductSlidesSetupById: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.Settings}`,

  toProductSlidesEmbedById: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.Embed}`,

  toProductSlidesAdvertsById: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.Adverts}`,

  toProductSlideConfig: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
    id: number | string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/slide/${id}`,

  toProductAddNew: (
    companySlug: string,
    websiteSlug: string,
    productSlug: string,
  ): string =>
    `/company/${companySlug}/${websiteSlug}/${productSlug}/${PluginItemShowTypeEnum.AddNew}`,

  toCompanyOrWebsiteStatisticBySlug: (
    companySlug = '',
    websiteSlug = '',
  ): string => `/company/${companySlug}/${websiteSlug}`,

  //company
  company: `/company/:companySlug/:type(edit|move)`,

  toCompanyEdit: (companySlug: string): string =>
    `/company/${companySlug}/edit`,
  companyEdit: `/company/:companySlug/:type(edit)`,

  toCompanyMove: (companySlug: string): string =>
    `/company/${companySlug}/move`,
  companyMove: `/company/:companySlug/:type(move)`,

  //website
  website: `/company/:companySlug/:websiteSlug/:type(edit|move)`,

  toWebsiteEdit: (companySlug: string, websiteSlug: string): string =>
    `/company/${companySlug}/${websiteSlug}/edit`,
  websiteEdit: `/company/:companySlug/:websiteSlug/:type(edit)`,

  toWebsiteMove: (companySlug: string, websiteSlug: string): string =>
    `/company/${companySlug}/${websiteSlug}/move`,
  websiteMove: `/company/:companySlug/:websiteSlug/:type(move)`,

  // Users And Archive
  // usersAndArchive: '/:type(user|archive)/:companySlug?/:websiteSlug?',
  //usersAndArchive: '/:type(archive)/:companySlug?/:websiteSlug?',
  // Users
  user: `/user`,
  userDetailRoute: `/user/:companySlug/:id`,
  toUserDetail: (companySlug: string, id: number) =>
    `/user/${companySlug}/${id}`,
  authUserDetailRoute: `/user/profile`,
  // Archive
  archive: `/archive`,
  archiveWebsite: `/archive/:companySlug`,
  toArchivedWebsite: (company: string) => `/archive/${company}`,
  archiveProduct: `/archive/:companySlug/:websiteSlug`,
  toArchivedProduct: (company: string, website: string) =>
    `/archive/${company}/${website}`,
};
