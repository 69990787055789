import styled from 'styled-components';
import { Layout } from 'antd';

export const UsersPageStyled = styled.div`
  // max-height: 100%;
  // height: 100%;
  // overflow: hidden;

  .pageTitle {
    border-bottom: 2px solid #f0f1f7;
  }

  .pageTitle span {
    display: block;
    font-family: 'Noir Pro Bold';
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 34px;
  }

  .search {
    display: flex;
    margin: 10px 0px;
    color: #808e9b;
    background-color: #e8edf8;
    padding: 7px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
  }
  .search .searchLeftContent {
    width: 100%;
    height: 40px;
    padding: 0px 30px;
  }
  .search .searchLeftContent input {
    width: 100%;
    height: 40px;
    color: #808e9b;
    border: none;
    outline: none;
    background: transparent;
  }
  .search .searchLeftContent input:focus::placeholder {
    color: rgb(128 142 156 / 70%);
  }

  .search .searchRightContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search .searchRightContent .divider {
    margin: 0px 30px;
  }

  .search .anticon-search {
    font-size: 22px;
    color: #808e9b;
    font-weight: bold;
  }

  .divider {
    width: 2px;
    height: 22px;
    background-color: #c9d0dc;
  }

  .addUser {
    padding: 0px 22px;
    height: 40px;
    background-color: #01ae92;
    color: white;
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .addUser:hover {
    opacity: 0.8;
  }

  .addUser > div {
    white-space: nowrap;
    display: block;
    margin-right: 12px;
  }

  .usersList {
    background: white;
    border-radius: 10px;
    box-shadow: rgb(24 24 24 / 5%) 0px 10px 30px;
    padding: 0px 30px;
  }

  .scrollable {
    overflow-y: auto;
    height: calc(84vh - 16rem);
    &::-webkit-scrollbar {
      width: 1.375rem !important;
      height: 1.125rem !important;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.5rem solid rgba(0, 0, 0, 0);
    }
  }

  @media only screen and (max-width: 550px) {
    .scrollable {
      padding: 0 1rem;
    }
  }
`;