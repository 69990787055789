import styled from 'styled-components';

export const SelectTimeRangeStyled = styled.div`
  background: white !important;
  height: 40px;
  background-color: #f6f9fc38;
  border-radius: 10px;
  border: 1px solid #9FA2AF4D;
  @media only screen and (max-width: 1330px) {
    height: 30px;
    max-width: 250px;
  }
  @media only screen and (max-width: 680px) {
    height: 30px;
    max-width: 100%;
    flex-grow: 1;
    margin-bottom: -15px;
    margin-top: 20px;
    .ant-picker-range {
      width: 100%;
    }
  }
`;
