import { Col, Row } from 'antd';
import React from 'react';
import { UserAddFieldsRowStyled } from './UserAddFieldsRow.styled';
import { ReactComponent as AccountInviteUsers } from 'assets/image/svg/account-invite-users.svg';
import { Controller, DeepMap, FieldError } from 'react-hook-form';
import { BaseInput, BaseSelect } from 'components/baseComponents';
import { FormPageTypeEnum } from 'utils/enums';

type UserAddFieldsRowPropsType<T> = {
  control: any;
  index: number;
  item: InviteUserFormType;
  errors: DeepMap<T, FieldError> | any;
  itemsArray: SelectItemType[];
};
const UserAddFieldsRow = <InviteUsersFormType extends Record<string, string>>({
  control,
  index,
  item,
  errors,
  itemsArray,
}: UserAddFieldsRowPropsType<InviteUsersFormType>): React.ReactElement => {
  return (
    <UserAddFieldsRowStyled
      className="user-add-fields-row"
      align={'middle'}
      $hasErrorAtLeft={
        !!errors.people?.[index]?.last_name ||
        !!errors.people?.[index]?.first_name
      }
    >
      <Row className={'form-item__name-container'}>
        <Controller
          control={control}
          name={`people[${index}].first_name`}
          defaultValue={item.first_name}
          inputTitle={false}
          // placeholder={'First name'}
          label={"First name"}
          as={BaseInput}
          error={errors.people?.[index]?.first_name}
        />
        <Controller
          control={control}
          name={`people[${index}].last_name`}
          as={BaseInput}
          defaultValue={item.last_name}
          inputTitle={false}
          label={'Last name'}
          error={errors.people?.[index]?.last_name}
        />
      </Row>
      <Row className={'form-item__email-container'}>
        <Controller
          control={control}
          name={`people[${index}].email`}
          as={BaseInput}
          defaultValue={item.email}
          inputTitle={false}
          label={'Email'}
          error={errors.people?.[index]?.email}
        />
      </Row>
      <Row className={'form-item__user-type-container'}>
        <Controller
          control={control}
          name={`people[${index}].type`}
          label={'User Role'}
          as={<BaseSelect size={'large'} />}
          defaultValue={item.type}
          page={FormPageTypeEnum.addUsers}
          itemsArray={itemsArray}
          error={errors.people?.[index]?.role}
        />
      </Row>
    </UserAddFieldsRowStyled>
  );
};

export default UserAddFieldsRow;
