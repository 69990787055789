import React from 'react';
import { Link } from 'react-router-dom';
import { svg } from 'assets/image';
import { BaseButton } from 'components/baseComponents';
import { PublicHeaderStyled } from './PublicHeader.styled';

export default function PublicHeader() {
  return (
    <PublicHeaderStyled>
      <Link to="/">
        <img
          src={svg.logo}
          alt="Logo"
          className="logo"
          width="162px"
          height="60px"
        />
      </Link>
      <div className="navbar-links">
        <Link to="/faq" className="faq-link">
          F.A.Q.
        </Link>
        <Link to="/register">
          <BaseButton text="Get it free" backgroundColor="#4B4DC8" />
        </Link>
      </div>
    </PublicHeaderStyled>
  );
}
