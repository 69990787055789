import { Col,Row } from 'antd';
import styled, { css } from 'styled-components';

type TableHeadSortableItemStyledPropsType = {
  $active: boolean;
  $sortingOrderIsAsc: boolean;
};
export const TableHeadSortableItemStyled = styled.div<TableHeadSortableItemStyledPropsType>`
  .text-wrapper {
    cursor: pointer;
    p {
      color: #181818;
      margin-right: 0.4rem;
    }
    svg path {
      fill: #808E9B;
    }
  }

  ${({ $active, $sortingOrderIsAsc }) =>
    $active &&
    css`
      .text-wrapper {
        p {
          color: #4B4DC8;
        }
        svg {
          path {
            fill: #4B4DC8;
          }
        }
      }
    `}
`;
