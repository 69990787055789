import { Button, message, Row } from 'antd';
import * as Api from 'api'
import { BaseSecondaryButton } from 'components/baseComponents';
import { LoaderLine } from 'components/common/userMove';
import { Text, Title } from 'components/styles';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ActivateWebsiteModalStyled } from './ActivateWebsiteModal.styled';
import { ajax, endpoints, restoreWebsite } from 'services';
import { trigger } from 'swr';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { useHistory } from 'react-router-dom';
import { routesPaths } from 'utils/helpers';

const ActivateWebsiteModal = ({
  onClose,
  name,
  slug,
  company_slug,
  company_name,
}: ActivateWebsiteModalPropsType): React.ReactElement => {
  const { siderActions } = useContext(SiderNavigationContext)
  const { fontFamilies } = useContext(ThemeContext);
  const { push } = useHistory()

  const onActivate = async () => {
    try {
      await restoreWebsite(company_slug, slug);
      trigger(endpoints.getCompanies);
      trigger(endpoints.siderTree);
      trigger(endpoints.getArchivedWebsites(company_slug));
      onClose();
      ajax.get(Api.endpoints.siderAllCompanies).then(response => {
        siderActions.setCompanies(response.data)
      })
      ajax.get(Api.endpoints.siderAllWebsites(company_slug)).then(response => {
        siderActions.setWebsites(response.data, company_slug)
      })
      ajax.get(Api.endpoints.siderAllProducts(company_slug, slug)).then(response => {
        siderActions.setProducts(response.data, slug)
      })
      push(routesPaths.toCompanyOrWebsiteStatisticBySlug(company_slug))
    } catch (error) {
      message.error(`Error: ${error.response.data.message}`);
    }
  };
  return (
    <ActivateWebsiteModalStyled>
      <Title.H2 color={'#5B59C2'}>Activate Website</Title.H2>
      <Text
        className={'text'}
        fontFamily={fontFamilies.semiBold}
        color={'#000000'}
      >
        Activating <span>{name}</span>.
      </Text>
      <Text
        className={'text'}
        fontFamily={fontFamilies.semiBold}
        color={'#000000'}
      >
        We will also activate {company_name} company.
      </Text>
      <LoaderLine loading={false} />
      <Row
        align={'middle'}
        justify={'end'}
        className={'content__submit-container'}
      >
        <Button type={'text'} onClick={onClose} className={'skip-step'}>
          <Text
            fontSize={18}
            fontFamily={fontFamilies.regular}
            color={'#53536D66'}
          >
            CANCEL
          </Text>
        </Button>
        <BaseSecondaryButton
          title={'activate'}
          className={'btn--fill'}
          backgroundColor={'#5C5BC1'}
          htmlType={'submit'}
          onClick={onActivate}
        />
      </Row>
    </ActivateWebsiteModalStyled>
  );
};

export default ActivateWebsiteModal;
