import { Row } from 'antd';
import styled from 'styled-components';

type UserAddFieldsRowStyledType = {
  $hasErrorAtLeft?: boolean;
};
export const WebsiteAddFieldsRowStyled = styled(
  Row,
)<UserAddFieldsRowStyledType>`
  // margin: 1.125rem 0;
  .form-item__name-container {
    background-color: #f6f9fc;
    border-radius: 6px;
    position: relative;
    & .ant-row {
      width: 100%;
    }
  }
  .form-item__email-container {
    padding: 0 0.92rem;
  }
`;
