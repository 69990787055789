import styled from 'styled-components';

export const SettingPageStyled = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  margin-top: -10px;
  form {
    margin-bottom: -25px;
    @media only screen and (max-width: 700px) {
      margin-bottom: -10px;
    }
  }
  .ant-row {
    width: 100% !important;
  }
  .label {
    font-size: 18px;
    top: 30%;
  }
  .label-float {
    font-size: 10px;
    top: 22%;
  }
  .scrollable {
    overflow-y: overlay;
    /* &::-webkit-scrollbar {
        width: 0.1rem !important;

        height: 2rem;
    } */
    /* max-height: calc(100% - 4rem); */
    height: calc(100% - 7.8rem);
    .ant-row:last-of-type {
      border-bottom: 0px solid #e3ebf3 !important;
    }
    &::-webkit-scrollbar {
      width: .2rem !important;
      height: 1.125rem !important;
    }
  }
  /* @media only screen and (max-width: 1200px) {
    .scrollable {
      padding: 4rem 2rem 0 2rem;
      .ant-select-selector {
        padding: 0 !important;
      }
      .ant-input {
        padding: 0;
      }
    }
  } */
  /* @media only screen and (max-width: 1000px) {
    form {
      min-width: 100%;
      .scrollable {
        padding: 4rem 1rem 0rem 1rem;
        .ant-row {
          .ant-col-offset-1 {
            display: none;
          }
          display: flex;
          flex: 1;
        }
      }
    }
  } */
  /* @media only screen and (max-width: 850px) {
    form {
      .scrollable {
        padding: 4rem 2rem 0rem 2rem;
        .ant-row {
          flex-direction: column;
          .ant-col {
            min-width: 100%;
            margin: 0.25rem 0rem;
            .ant-row {
              flex-direction: row;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .form-submit-buttons {
      padding: 0rem;
      margin: 2rem auto !important;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      .btn--add-new {
        padding: 1.5rem 2rem;
        margin-bottom: 0.5rem;
        border-radius: 5rem;
      }
    }
    .line-loader-container {
      display: none;
    }
    form {
      .scrollable {
        padding: 4rem 1.5rem 0rem 1.5rem;
        .setup-item__left-side {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .ant-input {
          padding: 0;
        }
      }
    }
  } */
`;
