import { useForm } from 'react-hook-form';
import { showCompanyUser, updateUser, resendUserInvite } from 'services';
import { CompanyUserNull } from './const';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { routesPaths } from '../../../utils/helpers';
import { message } from 'antd';
import { validateAxiosErrorInForms } from '../../../utils/helpers/functions';
import { useState } from 'react';
import { emailRegexp } from 'utils/helpers/const';
import { AxiosError } from 'axios';

type ParamsType = {
  companySlug: string;
  id: string;
};

const schema = Yup.object().shape({
  first_name: Yup.string().required('First name is required').max(50, 'Max 50 characters allowed.'),
  last_name: Yup.string().required('Last name is required').max(50, 'Max 50 characters allowed.'),
  email: Yup.string().matches(emailRegexp, 'Invalid email address.').required('Email is required'),
});

type PersonalInfo = {
  id: string;
  isDisabled: boolean;
  name: string;
  value: string;
  placeholder: string;
  characterCount?: boolean;
};

const useUserDetails = () => {
  const { replace } = useHistory();
  const { companySlug, id } = useParams<ParamsType>();
  const { data: UserData } = showCompanyUser(companySlug, Number(id));
  const [companies, setCompanies] = useState<UserCompanyType[]>([]);

  const User = UserData?.data || CompanyUserNull;
  const avatar = User.avatar as string;

  const { is_active } = User;
  const fields: PersonalInfo[] = [
    {
      id: User?.initial_company,
      isDisabled: true,
      name: 'initial_company',
      value: User?.initial_company,
      placeholder: 'Company',
      characterCount: false
    },
    {
      id: User?.first_name,
      isDisabled: !is_active,
      name: 'first_name',
      value: User?.first_name,
      placeholder: 'First name',
    },
    {
      id: User?.last_name,
      isDisabled: !is_active,
      name: 'last_name',
      value: User?.last_name,
      placeholder: 'Last name',
    },
    {
      id: User?.email,
      isDisabled: !is_active,
      name: 'email',
      value: User?.email,
      placeholder: 'Email',
    },
  ];

  const { available_companies } = User;

  const { handleSubmit, control, errors, register, setValue, getValues, setError } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    let cleanedCompaniesList = [...companies];
    const companyLevelPermission = cleanedCompaniesList.map((company) => company.has_access);

    if (companyLevelPermission.indexOf(true) === -1) {
      message.error('Active user must be related to at least one company.');
      return;
    }

    cleanedCompaniesList = cleanedCompaniesList.map((company) => {
      return {
        name: company.name,
        slug: company.slug,
        has_access: company.has_access,
        access: company.access,
        created: company.created,
        updated: company.updated,
        user_role: company.user_role,
        id: company.id,
        role: company.user_role,
        websites: company.websites.map((website) => {
          return {
            id: website.id,
            permission: website.has_access,
            name: website.name,
            slug: website.slug,
            created: website.created,
            updated: website.updated,
            url: website.url,
            has_access: website.has_access,
          };
        }),
      };
    });

    const params: UserSubmitInfo = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      companies: cleanedCompaniesList as unknown as CompanySubmitInfo[],
    };

    try {
      await updateUser(companySlug, Number(id), params);
      message.success('User updated!');
      replace(routesPaths.user);
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    }
  });

  const onResendInvite = async () => {
    try {
      const resp = await resendUserInvite(User?.email);
      message.info(resp.data.message);
    } catch (error) {
      message.error(`Error: ${(error as AxiosError).response?.data.message}`);
    }
  };

  return {
    available_companies,
    handleSubmit,
    control,
    errors,
    onSubmit,
    User,
    avatar,
    getValues,
    setValue,
    fields,
    companies,
    setCompanies,
    register,
    companySlug,
    onResendInvite,
  };
};

export default useUserDetails;
