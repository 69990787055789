import React, { useContext, useState } from 'react';
import { UserMoveStyledContainer } from './UserMove.styled';
import { Button, Col, Row, Typography } from 'antd';
import useUserMove from './useUserMove';
import { Text } from '../../styles';
import {
  BaseSecondaryButton,
  BaseSelect,
  BaseCheckbox,
} from '../../baseComponents';
import { LoaderLine } from 'components/common/userMove';
import { ThemeContext } from 'styled-components';
import { Controller } from 'react-hook-form';

const UserMove = ({ onClose }: MoveUserPropsType): React.ReactElement => {
  const {
    control,
    handleSubmit,
    onSubmit,
    loading,
    getRoles,
    getCompanies,
    getUsers,
  } = useUserMove('');
  const [check, setCheck] = useState(false);
  const { fontFamilies } = useContext(ThemeContext);

  const { Title } = Typography;

  return (
    <UserMoveStyledContainer onSubmit={handleSubmit(onSubmit)}>
      <Title level={2}>Move User</Title>
      <Row align={'middle'} className={'form-container__company-select'}>
        <Col>
          <Controller
            control={control}
            name={'company_id'}
            as={BaseSelect}
            itemsArray={getCompanies}
            label={`Move user to`}
          />
        </Col>
        <Col>
          <Controller
            control={control}
            name={'roles'}
            as={BaseSelect}
            itemsArray={getRoles}
            label={`Access level`}
            defaultValue={`Company Admin`}
          />
        </Col>
      </Row>
      <Row align={`middle`} className={`form-container__checkbox`}>
        <BaseCheckbox
          label={
            check
              ? 'Re-assign all entities to'
              : 'Re-assign current user entities to'
          }
          checked={check}
          onChange={() => {
            setCheck(!check);
          }}
        />
      </Row>
      <Controller
        control={control}
        name={'users'}
        as={BaseSelect}
        itemsArray={getUsers}
        disabled={!check}
      />
      <LoaderLine loading={loading} />
      <Row
        align={'middle'}
        justify={'end'}
        className={'content__submit-container'}
      >
        <Button type={'text'} onClick={onClose} className={'skip-step'}>
          <Text
            fontSize={18}
            fontFamily={fontFamilies.regular}
            color={'#53536D66'}
          >
            CANCEL
          </Text>
        </Button>
        <BaseSecondaryButton
          title={'NEXT'}
          className={'btn--fill'}
          backgroundColor={'#5C5BC1'}
          htmlType={'submit'}
        />
      </Row>
    </UserMoveStyledContainer>
  );
};

export default UserMove;
