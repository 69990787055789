import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import Default from 'assets/image/images/defaultImage.png';
import { useProductContainerContext } from 'state/productContainer/state/context';
import { ImageTypes, VideoTypes } from 'utils/constants';
import { message } from 'antd';

type SlideObjType = SlideObjectTypeConfigPage | undefined | null;

interface ISlideConfigPageContext {
  // stores
  readonly dates: {
    readonly startDate: string | Moment;
    readonly endDate: string | Moment;
    readonly duration: number;
    readonly time: string;
  };
  readonly image: {
    readonly type: string;
    readonly url: string;
  };
  readonly isValidDate: boolean;
  readonly slideObj: SlideObjType;
  readonly settings: ProductPageSettingsType | undefined | null;
  readonly loading: boolean;
  readonly file: File | null;

  // actions
  readonly setDates: Dispatch<
    SetStateAction<{
      readonly startDate: string | Moment;
      readonly endDate: string | Moment;
      readonly duration: number;
      readonly time: string;
    }>
  >;
  readonly setImage: Dispatch<
    SetStateAction<{
      readonly type: string;
      readonly url: string;
    }>
  >;
  readonly setIsValidDate: Dispatch<SetStateAction<boolean>>;
  readonly setSlideObj: Dispatch<SetStateAction<SlideObjType>>;
  readonly setSettings: Dispatch<SetStateAction<ProductPageSettingsType | undefined | null>>;
  readonly setLoading: Dispatch<SetStateAction<boolean>>;
  readonly setFile: Dispatch<SetStateAction<File | null>>;

  // fns
  readonly validateDates: () => void;
  readonly onSlideUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface Props {
  readonly children: React.ReactNode;
}

const SlideConfigPageContext = createContext({} as ISlideConfigPageContext);

const addDaysToDate = (duration: number) => {
  const date = new Date();
  date.setDate(date.getDate() + duration);
  return date;
};

const SlideConfigPageProvider = ({ children }: Props) => {
  const { slideData } = useProductContainerContext();

  const [dates, setDates] = useState({
    startDate: moment(new Date().toISOString(), 'YYYY-MM-DD'),
    endDate: moment(
      addDaysToDate(Number(slideData.duration)).toISOString(),
      'YYYY-MM-DD',
    ),
    duration: 0,
    time: '9:00 am',
  });

  const [loading, setLoading] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);
  const [slideObj, setSlideObj] = useState<SlideObjType>(null);
  const [settings, setSettings] = useState<ProductPageSettingsType | undefined | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState({
    type: 'image/png',
    url: Default,
  });

  // FNs
  const validateDates = useCallback(() => {
    const start = moment(dates.startDate, 'ddd YYYY-MM-DD').toDate().getTime();
    const end = moment(dates.endDate, 'ddd YYYY-MM-DD').toDate().getTime();
    const isValidDate = end - start > 0;
    setIsValidDate(isValidDate);
  }, [dates.startDate, dates.time, dates.endDate, slideObj]);

  const onSlideUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) return;
    if (e.target.files.length > 1) return;
    const val = e.target.files[0];
    if (ImageTypes.some((type) => type === val.type)) {
      if (val.size > 2097152) {
        message.error('File is larger than 2mb');
        return;
      }
    } else if (VideoTypes.some((type) => type && type === val.type)) {
      if (val.size > 20971520) {
        message.error('File is larger than 20mb');
        return;
      }
    } else {
      message.error('Please upload JPG, PNG or mp4 type files');
      return;
    }

    const url = URL.createObjectURL(val);
    setFile(val);
    setImage({
      type: val.type,
      url,
    });
  };

  return (
    <SlideConfigPageContext.Provider
      value={{
        isValidDate,
        setIsValidDate,
        dates,
        slideObj,
        setSlideObj,
        settings,
        setSettings,
        image,
        setImage,
        loading,
        setLoading,
        validateDates,
        file,
        setFile,
        onSlideUpload,
        // @ts-expect-error TODO: fix types
        setDates,
      }}
    >
      {children}
    </SlideConfigPageContext.Provider>
  );
};

export { SlideConfigPageContext, SlideConfigPageProvider };
