import React, { ModalTypeEnum } from "components/modal/ModalCustom";
import { createContext, useState } from "react";

interface IHomePageContext {
    readonly modalType: ModalTypeEnum
    readonly modalVisible: boolean
    readonly setModalVisible: (visible: boolean) => void
    readonly setModalType: (modalType: ModalTypeEnum) => void
}

interface Props {
    readonly children: React.ReactNode
}

const HomePageContext = createContext({} as IHomePageContext)

const HomePageProvider = ({ children }: Props) => {
    // State
    const [modalType, setModalType] = useState(ModalTypeEnum.addUsers)
    const [modalVisible, setModalVisible] = useState(false)

    return (
        <HomePageContext.Provider
            value={{
                modalType,
                setModalType,
                modalVisible,
                setModalVisible
            }}
        >
            {children}
        </HomePageContext.Provider>
    )
}

export { HomePageContext, HomePageProvider }