import React from 'react';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';

import {
    StatisticPageLazy,
} from '../../containers';

const statisticPageWrapper = (): React.ReactElement => {
    return (
        <DashboardLayout>
            <StatisticPageLazy />
        </DashboardLayout>
    );
};

export default statisticPageWrapper;