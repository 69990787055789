import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from 'state/appContext';

import 'utils/localization/i18';
// TODO: uncomment this when done development
// import 'utils/sentry';

ReactDOM.render(
  <Suspense fallback={<div>loading</div>}>
    <AppProvider>
      <App />
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: ` var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/619618a16bb0760a49432cfd/1fkp50eig';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();`,
          },
          {
            type: 'text/javascript',
            innerHTML: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.REACT_APP_GOOGLE_TAG_ID}');`,
          },
          {
            type: 'text/javascript',
            async: true,
            src: 'https://www.googletagmanager.com/gtag/js?id=' + process.env.REACT_APP_GOOGLE_TAG_ID,
          },
        ]}
      ></Helmet>
    </AppProvider>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
